import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Rodape extends Component {

    constructor(props){
        super(props);
    }

    data_atual = new Date();

    render(){
        return(
            <div>
                <div className="b-info">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-xs-6">
                                <address className="b-info__contacts wow zoomInUp" data-wow-delay="0.3s">
                                    <p className="titulo-rodape">SOBRE NÓS</p>
                                    <div className="b-info__contacts-item">
                                        <em>{ this.props.revenda.texto_sobre_nos_site }</em>
                                    </div>
                                </address>
                            </div>
                            <div className="col-md-3 col-xs-6">
                                <div className="b-info__latest">
                                    <h3 className="titulo-rodape">ATENDIMENTO</h3>
                                    <address className="b-info__contacts wow zoomInUp" data-wow-delay="0.3s">
                                        <div className="b-info__contacts-item">
                                            <em>Seg-Sex : { this.props.revenda.horario_seg_sex }<br />
                                                Sab : { this.props.revenda.horario_sab }</em>
                                        </div>
                                    </address>
                                </div>
                            </div>
                            <div className="col-md-3 col-xs-6">
                                <address className="b-info__contacts wow zoomInUp" data-wow-delay="0.3s">
                                    <p className="titulo-rodape">FALE CONOSCO</p>
                                    <div className="b-info__contacts-item">
                                        <span className="fa fa-map-marker"></span>
                                        <em> { this.props.revenda.peendereco_fiscal } { this.props.revenda.peendereco_fiscal_numero } { this.props.revenda.peendereco_fiscal_compl } - { this.props.revenda.pebairro_fiscal }<br/>
                                             { this.props.revenda.pecidade }-{ this.props.revenda.peuf }</em>
                                    </div>
                                    <div className="b-info__contacts-item">
                                        <span className="fa fa-phone"></span>
                                        <em>Telefone: <a href={ "tel:" + retiraMascara(this.props.revenda.petelefone1, "link") } >{ retiraMascara(this.props.revenda.petelefone1) }</a></em>
                                    </div>                                    
                                    {
                                        this.props.revenda.petelefone2 !== undefined && this.props.revenda.petelefone2.trim().length > 0 ?
                                            (
                                                <div className="b-info__contacts-item">
                                                    <span className="fa fa-whatsapp"></span>
                                                    <em>Whatsapp: <a href={ 'https://api.whatsapp.com/send?phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") }>{ retiraMascara(this.props.revenda.petelefone2) }</a></em>
                                                </div>
                                            )
                                            : <div></div>
                                    }
                                    {
                                        this.props.revenda.petelefone3 !== undefined && this.props.revenda.petelefone3.trim().length > 0 ?
                                            (
                                                <div className="b-info__contacts-item">
                                                    <span className="fa fa-whatsapp"></span>
                                                    <em>Whatsapp: <a href={ 'https://api.whatsapp.com/send?phone=55'+retiraMascara(this.props.revenda.petelefone3, "link") }>{ retiraMascara(this.props.revenda.petelefone3) }</a></em>
                                                </div>
                                            )
                                            : <div></div>
                                    }
                                    <div className="b-info__contacts-item">
                                        <span className="fa fa-envelope"></span>
                                        <em>E-mail: <a href={ "mailto:" + this.props.revenda.peemail }>{ this.props.revenda.peemail }</a></em>
                                    </div>
                                </address>
                            </div>
                            <div className="col-md-3 col-xs-6">
                                <address className="b-info__contacts wow zoomInUp" data-wow-delay="0.3s">
                                    <p className="titulo-rodape">VENHA NOS VISITAR</p>
                                    <a target="_blank" href={ this.props.revenda.mapa_link } className="wow slideInLeft" rel="noopener noreferrer" >
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3778.4942924259612!2d-47.49525648255617!3d-18.731445700000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94a588902cfd6691%3A0x5fcab8bde6d289d5!2sSCHOPP%20CAR!5e0!3m2!1spt-BR!2sbr!4v1648063227001!5m2!1spt-BR!2sbr" width="200" height="200" allowfullscreen="" loading="lazy"></iframe>
                                    </a>
                                </address>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="b-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-4">
                                <div className="b-footer__company wow fadeInLeft" data-wow-delay="0.3s">
                                    <div className="">
                                        <img src={ this.props.revenda.logotipo } alt={ "Logotipo " + this.props.revenda.penome_fantasia } style={{ height: "60px" }} />
                                    </div>
                                    <p>
                                        { this.data_atual.getFullYear() } Todos os direitos reservados. { this.props.revenda.penome_fantasia }.
                                    </p>
                                </div>
                            </div>
                            <div className="col-xs-8">
                                <div className="b-footer__content wow fadeInRight" data-wow-delay="0.3s" style={{ marginRight: "40px" }}>
                                    <div className="b-footer__content-social">
                                        <a href={ this.props.revenda.facebook_link } target="_blank" rel="noopener noreferrer"><span className="fa fa-facebook-square"></span></a>
                                        <a href={ this.props.revenda.instagram_link } target="_blank" rel="noopener noreferrer"><span className="fa fa-instagram" target="_blank"></span></a>
                                        {
                                            this.props.revenda.petelefone2 !== undefined && this.props.revenda.petelefone2.trim().length > 0 ?
                                                (
                                                    <a href={ 'https://api.whatsapp.com/send?phone=55'+retiraMascara(this.props.revenda.petelefone2, "link") } target="_blank" rel="noopener noreferrer" className="botao-whatsapp">
                                                        <i className="fa fa-whatsapp"></i>
                                                    </a>
                                                )
                                                : <span></span>
                                        }
                                    </div>
                                    <nav className="b-footer__content-nav">
                                        <ul>
                                            <li><Link to="/" > Início</Link></li>
                                            <li><Link to="/venda-seu-veiculo" > Venda seu veículo</Link></li>
                                            <li><Link to="/financiamento" > Financiamento</Link></li>
                                            <li><Link to="/contato" > Contato</Link></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            
        );
    }
}

function retiraMascara(value, type = "texto"){
    var numero = '';
    if(value !== undefined){
        numero = value.replace('_', '');
        if(type === "link"){
            numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        }
    }
    return numero;
}

export default Rodape;